import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bs-table-fixed-header"
export default class extends Controller {
  connect(event) {
  	console.log("bootstrap table connect")
    //alert($(window).width())


/*
    var doc1 = new jsPDF();
    doc1.setFont("arial", "bold");
    doc1.text(20, 20, 'This PDF has a title, subject, author, keywords and a creator.');

    // Optional - set properties on the document
    doc1.setProperties({
     title: 'Title',
     subject: 'This is the subject',
     author: 'Author Name',
     keywords: 'generated, javascript, web 2.0, ajax',
     creator: 'Creator Name'
    });

    // Output as Data URI
    //doc1.save('Test.pdf');
*/

    
    const win_height = $( window ).height();
    var offset = $(this.element).data("offset");
    console.log(offset);
    if (typeof offset === "undefined") {
      offset = 120;
    }

    function DoBeforeAutotable(table, headers, rows, AutotableSettings) {
      var doc = AutotableSettings.tableExport.doc
      //doc.setFont('arial', 'normal');
      var title = table.attr("data-title")
      //doc.setFontSize(20);
      //doc.setTextColor(40);                      
      //doc.text(title, AutotableSettings.margin.left, 60);
      console.log(doc.getFontList())
    }

    function DoAfterAutotable(table, AutotableSettings) {
      var doc = AutotableSettings.tableExport.doc
      var pageCount = doc.getNumberOfPages()
      for(var i = 0; i < pageCount; i++) { 
        doc.setPage(i); 
        //doc.setFont("arial", "bold");
        doc.setFontSize(10);
        // jsPDF 1.4+ uses getHeight, <1.4 uses .height
        var pageSize = doc.internal.pageSize
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
        doc.text("Pagina " + doc.internal.getCurrentPageInfo().pageNumber + " di " + pageCount, AutotableSettings.margin.left, pageHeight - 20)
      }

    }

    var title = $(this.element).find("table").data("title");

  	
  	$('#table-container table').bootstrapTable({
      height: win_height-offset,
      exportOptions: {
          jspdf: {
              orientation: "p",
              margins: {left: 20, right: 10, top: 70, bottom: 30},
              autotable: {
                styles: {
                  rowHeight: 15
                }, 
                startY: 70,
                tableExport: {
                  onBeforeAutotable: DoBeforeAutotable,
                  onAfterAutotable: DoAfterAutotable
                },
                beforePageContent: function (data) {
                  var doc = data.doc;
                  //doc.setFont("arial", "bold");
                  doc.setFontSize(20);
                  doc.setTextColor(40);
                  doc.setFont('helvetica', 'normal');
                  
                  doc.text(title, data.settings.margin.left, 60);
                }
              }
          }
      }
    });
    

    var $el = $(this.element)

    $(document).on('click','table.row-click-enabled tr',function(e){  
      //alert("click")
      //alert($(this).attr("data-post-id"));
      var tableName = $el.attr("data-table")
      var fieldName = $el.attr("data-field")
      //alert(tableName)
      //alert(fieldName)
      //alert($(this).data(fieldName))
      Turbo.visit("/"+tableName+"/" + $(this).data(fieldName))      
    });

    $(document).on('click-row.bs.table','table.row-click-enabled',function(e, row, $element, field){  
      //if (field != "actions") { 
        
        alert('click su riga')
        //alert($el.attr("data-table"))
        var tableName = $el.attr("data-table")
        var fieldName = $el.attr("data-field")
        //alert(fieldName)
        Turbo.visit("/"+tableName+"/" + $element.data(fieldName))
      //}
    });  

  	// Warehouse Operation - products selection modal
  	//if called by request add lines to form automatically
  	if ($("#exampleModal table.products").length > 0) {
	    if ($("#warehouse_operation_request_id").val() != "") {
	      var $productsTableRows = $("#exampleModal table.products tr")
	      $productsTableRows.each(function(index, tr) { 
	        if ($(tr).find(".qty").val() != "" && $(tr).find(".qty").val() !== undefined) {
	          //alert("click")
	          $(tr).find(".add_fields").click()
	        }
	      });
	    }
	  }

    $(window).resize(function(){
      const win_height= $( window ).height();
      var offset = $(this.element).data("offset");
      if (typeof offset === "undefined") {
        offset = 120;
      }
      $("table.table").bootstrapTable('refreshOptions', {
        height: win_height-offset
      })
    });

  }

  teardown() {
  	console.log("tear")
  	$("#table-container table").bootstrapTable('destroy');
  }

  refresh() {
  }

}
